import React, {CSSProperties, FC} from 'react';
import Carousel from 'react-material-ui-carousel';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import SquareIcon from '@mui/icons-material/Square';
import {Breakpoint} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

//TYPES
export type NewCarouselProps = {
  data: { id: number, title: string, image: string }[],
};

//STYLES
const collaboratorImage: CSSProperties = {
  filter: 'grayscale(100%)',
  maxHeight: '150px',
  maxWidth: '100%',
  opacity: 0.5,
  objectFit: 'contain'
};


const NewCarousel: FC<NewCarouselProps> = ({data}) => {

  const getCurrentDeviceBreakpoint = (): Breakpoint => {
    const theme = useTheme();

    const isXs = useMediaQuery(theme.breakpoints.only('xs'));
    const isSm = useMediaQuery(theme.breakpoints.only('sm'));
    const isMd = useMediaQuery(theme.breakpoints.only('md'));
    const isLg = useMediaQuery(theme.breakpoints.only('lg'));
    const isXl = useMediaQuery(theme.breakpoints.only('xl'));

    switch (true) {
    case isXs:
      return 'xs';
    case isSm:
      return 'sm';
    case isMd:
      return 'md';
    case isLg:
      return 'lg';
    case isXl:
      return 'xl';
    default:
      return 'md';
    }
  };

  const VISIBLE_ITEMS = {
    xs: 1,
    sm: 2,
    md: 4,
    lg: 4,
    xl: 6
  };

  const getVisibleItems = (): number => {
    const currentBreakpoint: Breakpoint = getCurrentDeviceBreakpoint();
    return VISIBLE_ITEMS[currentBreakpoint];
  };

  const visibleItems: number = getVisibleItems();

  const sliderItems: number = data.length > visibleItems ? visibleItems : data.length;
  const items: Array<any> = [];

  for (let i = 0; i < data.length; i += sliderItems) {
    if (i % sliderItems === 0) {
      items.push(
        <Grid container spacing={0} sx={{justifyContent: 'center', height: '200px', alignContent: 'center', '& .MuiGrid-item': {pt: 0}}}>
          {
            data.slice(i, i + sliderItems).map((item, i) => {
              return <Grid item key={i} xs={Math.trunc(12/visibleItems)} sx={{display: 'grid', placeItems: 'center'}}>
                <Box sx={{display: 'grid', placeItems: 'center', height: '150px'}}>
                  <img src={item.image} alt={item.title} style={collaboratorImage}/>
                </Box>
              </Grid>;
            })}
        </Grid>
      );
    }
  }
  return <Box sx={{height: '200px'}}>
    <Carousel
      autoPlay={false}
      navButtonsAlwaysInvisible={true}
      animation='fade'
      duration={100}
      indicators={true}
      IndicatorIcon={<SquareIcon sx={{fontSize:'14px', mx:0.25}}/>}
    >
      {items}
    </Carousel>
  </Box>;
};

export default NewCarousel;