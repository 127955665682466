import React, {FC} from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Header from './Header';
import MainCover from './MainCover';
import Collaborators from '@/components/landing/Collaborators';
import MiniMap from './MiniMap';
import BasicData from '../BasicData';
import Footer from './Footer';
import SpeciesAndResourcesCarousel from '@/components/SpeciesAndResources/SpeciesAndResourcesCarousel';
import ResourceData from '@/components/Resources/ResourceData';
import SpecieDataDetail from '@/components/Species/SpecieDataDetail';
import ActionPlan from '@/components/landing/ActionPlan/ActionPlan';
import TestimonyItem from '@/components/Testimonials/TestimonyItem';
import Territory from '@/components/landing/Territory/Territory';
import Commitment from '@/components/landing/Commitment';
import SustainabilityActionPanel from '@/components/landing/SustainabilityActions/SustainabilityActionPanel';
import {Initiative, Resource} from '@/domain/entities/Initiative';
import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';
import {useTranslation} from 'react-i18next';
import {useMediaQuery} from '@mui/material';
import {Specie} from '@/domain/entities/Specie';
import {Style} from 'mapbox-gl';

const backgroundImage: SxProps = {
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  height: 'auto',
  p: 4,
  backgroundImage: 'url(/img/bosque.jpg)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
};

const generalDataSx: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  m: 2,
  mt: 4
};

const speciesContainerSx: SxProps = {
  display: 'flex',
  bgcolor: 'common.white',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  p: 2,
  mb: 2
  
};

export interface LayoutProps {
  initiative: Initiative,
  mapStyle: Style | string,
  mapStyleId: string,
  onStyleChange: (mapStyleId: string) => void,
}

const Layout:  FC<LayoutProps> = ({initiative, mapStyle, mapStyleId, onStyleChange}) => {

  const {t} = useTranslation();
  const widescreen = useMediaQuery('(min-width: 900px)', {noSsr: true});

  const items: Array<Specie | Resource> = initiative.species ? [...initiative.species, ...initiative.resources] : initiative.resources;

  const performersNameStr = initiative.performers.map(performer => performer.name).join(', ');

  return <Box sx={{width: '100vw'}}>
    <Header performers={initiative.performers}/>
    <MainCover
      image={widescreen ? '/img/plant-growing-ground.jpg' : '/img/destacado-principal.jpg'}
      mainTitle={initiative.subtitle}
      mainText={initiative.description}
    />
    {/*MAP-----------------------------------------------------------------------------------------------------------*/}
    {
      initiative.parcels_rsc.features.length ?
        <Box sx={{position: 'relative', height: {xs: '500px', md: '500px'}}}>
          <MiniMap
            feature={initiative.parcels_rsc}
            bbox={initiative.bbox}
            mapStyle={mapStyle}
            mapStyleId={mapStyleId}
            onStyleChange={onStyleChange}
          />
          <Typography variant='subtitle' sx={{boxShadow: 1, position: 'absolute', top: 15, left: '50%', transform: 'translateX(-50%)', p: 1, bgcolor: 'common.white', borderRadius: 1, opacity: 0.9, border: '1px solid lightgrey'}}>{t('actionMap').toUpperCase()}</Typography>
        </Box> : undefined
    }
    {/*BASIC DATA-----------------------------------------------------------------------------------------------------------*/}
    <Box sx={{m: 2.5, borderRadius: 1}}>
      <BasicData
        certification_type={initiative.certification_type}
        name={initiative.name}
        title={initiative.title}
        performer={performersNameStr}
        place={initiative.place}
        objective={initiative.objective}
        investment={initiative.investment}
      />
    </Box>
      
    {/*ESPECIES---RECURSOS-----------------------------------------------------------------------------------------*/}
    {
      items.length 
        ? <Box sx={speciesContainerSx}>
          <Box sx={generalDataSx}>
            <Typography variant="subtitle" gutterBottom>{t('areaAndSpecies').toUpperCase()}</Typography>
            <Typography variant="title" color="primary" gutterBottom>{initiative.area.toFixed(2)} HA</Typography>
          </Box>
          {
            widescreen ?
              <Box sx={{display: items.length > 2 ? 'grid' : 'flex', alignItems: 'center', gap: 2, gridTemplateColumns: items.length > 2 ? 'repeat(3, 1fr)' : undefined}}>
                {
                  (initiative.species?.length || initiative.resources?.length) && items.map(item => (
                    'text' in item ?
                      <ResourceData key={item.id} resource={item} background={true} isDetail/>
                      : <SpecieDataDetail key={item.id} specie={item} background={true}/>
                  ))
                }
              </Box>
              : <SpeciesAndResourcesCarousel items={items} isDetail background/>
          }
        </Box>
        : undefined
    }
    
    {/*PLAN DE ACCIÓN----------------------------------------------------------------------------------------------*/}
    <Box pt={0} m={0} sx={{bgcolor: 'common.white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      {initiative.action_plan && <ActionPlan actionPlan={initiative.action_plan}/>}
    </Box>
    
    {/*TERRITORIO--------------------------------------------------------------------------------------------------*/}
    {
      initiative.meteo.temperature && initiative.meteo.sky_state && initiative.environmental_impacts.length > 0 &&
        <Box sx={backgroundImage}>
          <Territory meteo={initiative.meteo} environmentalImpacts={initiative.environmental_impacts} owners={initiative.owners}/>
        </Box>
    }
    
    {/*ACCIONES DE SOSTENIBILIDAD-----------------------------------------------------------------------------------*/}
    <Box pt={0} m={0} sx={{bgcolor: 'common.white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
      <SustainabilityActionPanel sustainabilityActions={initiative.sustainability_actions}/>
    </Box>
    {/*TESTIMONIOS--------------------------------------------------------------------------------------------------*/}
    { 
      initiative.testimony &&
        <TestimonyItem testimony={initiative.testimony}/>
    }
    {/*COMPROMISO--------------------------------------------------------------------------------------------------*/}
    {
      initiative.commitment &&
        <Commitment title={initiative.commitment.title} description={initiative.commitment.description} video={initiative.commitment.video}/>
    }
    {/*COLABORADORES-----------------------------------------------------------------------------------------------*/}
    {
      initiative.collaborators.length > 0 &&
        <Collaborators collaborators={initiative.collaborators}/>
    }

    <Footer/>
    
  </Box>;
};

export default Layout;
