import React, {CSSProperties, FC} from 'react';
import {useTranslation} from 'react-i18next';
import Typography from '@mui/material/Typography';
import {SummaryInitiativeData} from '@/utils/getSummaryInitiativesData';
import Certificates from '@/components/Certificates';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

//TYPES
export type CertificatesCollaboratorsProps = {
  initiativesSummary: SummaryInitiativeData
  hightlightedLogos: Array<string> | undefined
};

const CertificatesCollaborators: FC<CertificatesCollaboratorsProps> = ({initiativesSummary, hightlightedLogos}) => {
  
  //STYLES
  const collaboratorImage: CSSProperties = {
    filter: 'grayscale(100%)',
    height: '200px',
    maxWidth: `calc(100% / ${hightlightedLogos?.length})`,
    opacity: 0.5,
    objectFit: 'contain'
  };
  
  const {t} = useTranslation();

  return <Paper sx={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', mb: 1}} elevation={3}>
    <Typography variant="subtitle" align="center"
      sx={{mt: 4, mb: 2, fontWeight: 900, color: 'secondary.main'}}>{t('initiatives.summary.certifications').toUpperCase()}</Typography>
    <Box mx={4} sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: 1, pb: 3 }}>
      <Certificates certificates={initiativesSummary.certificationValues}/>
      {
        initiativesSummary.certificationValues && hightlightedLogos && hightlightedLogos.length !== 0 && <>
          <Divider/>
          <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', height: '100%'}}>
            {
              hightlightedLogos?.map((image, index) => <img key={index} style={collaboratorImage} src={image} alt=''/>)
            }
          </Box>
        </>
      }
    </Box>
  </Paper>;
};
export default CertificatesCollaborators;