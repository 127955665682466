import React, {FC} from 'react';

//MUI
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

//GREEN BONUS
import Bikenta_RSC from './../logos/Bikenta_RSC';

//UTILS
import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';
import {useTranslation} from 'react-i18next';

const containerSx: SxProps = {
  minHeight: '5vw',
  bgcolor: 'grey.400',
  display: 'flex',
  flexDirection:'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  px: 4,
  mt: 6
};

const linksWrapperSx: SxProps = {
  display: 'flex',
  flexDirection:'row',
  alignItems:'center',
  justifyContent:'flex-end',
  gap: 2
};

const Footer: FC = () => {

  const {t} = useTranslation();

  return <Box  sx={containerSx}>
    <Bikenta_RSC width='150px'/>
    <Box color='white'>
      <Typography variant='caption' sx={{fontWeight: 'bolder'}}>Version: </Typography>
      {/* eslint-disable-next-line no-undef */}
      <Typography variant='caption' sx={{mr: 1}}>{VERSION}</Typography>
      <Typography variant='caption' sx={{fontWeight: 'bolder'}}>Hash: </Typography>
      {/* eslint-disable-next-line no-undef */}
      <Typography variant='caption'>{HASH}</Typography>
    </Box>
    <Box sx={linksWrapperSx}>
      <Link href='https://www.gestionforestal.es/contacta/' underline='none' target='_blank'>
        <Typography variant='paragraphBold' color='secondary.light'>
          {t('footer.help')}
        </Typography>
      </Link>
      <Typography sx={{px: 2}} variant='paragraphBold' color='secondary.light'>|</Typography>
      <Link href='https://www.gestionforestal.es/nosotros/' underline='none' target='_blank'>
        <Typography variant='paragraphBold' color='secondary.light'>
          {t('footer.contact')}
        </Typography>
      </Link>
      <Typography sx={{mx: 2}} variant='paragraphBold' color='secondary.light'>|</Typography>
      <Link href='https://www.gestionforestal.es/gf-producto/bikenta-rsc/' underline='none' target='_blank'>
        <Typography variant='paragraphBold' color='secondary.light'>
          {t('footer.bikenta')}
        </Typography>
      </Link>
    </Box>
  </Box>;
};
export default Footer;