import React from 'react';

import EucaliptoGlobulus from '@/components/logos/arboles/EucaliptoGlobulus';
import EucaliptoNitens from '@/components/logos/arboles/EucaliptoNitens';
import Frondosas from '@/components/logos/arboles/Frondosas';
import PinusPinaster from '@/components/logos/arboles/PinusPinaster';
import PinusPinea from '@/components/logos/arboles/PinusPinea';
import PinusRadiata from '@/components/logos/arboles/PinusRadiata';
import PinusSylvestris from '@/components/logos/arboles/PinusSylvestris';
import Quercus from '@/components/logos/arboles/Quercus';
import {SkyState} from '@/domain/entities/Meteo';
import {CERTIFICATION_TYPES, SustainabilityActionWithIconType} from '@/domain/entities/Initiative';

export const APP_NAME = 'bonosverdes';

export const INITIAL_VIEWPORT = {
  latitude: 42.7491,
  longitude: -8.0777,
  zoom: 8,
  bearing: 0,
  pitch: 0
};

export const MAPSTYLES = [
  {
    'label': 'Hibrid',
    'thumbnail': 'https://openicgc.github.io/img/orto.png',
    'id': 'https://geoserveis.icgc.cat/contextmaps/hibrid.json',
    'firstTopLayer': 'place-other'
  },
  {
    'label': 'OSM Bright',
    'thumbnail': 'https://openicgc.github.io/img/osm-bright.png',
    'id': 'https://geoserveis.icgc.cat/contextmaps/osm-bright.json',
    'firstTopLayer': 'place-other'
  },
  {
    label: 'PNOA',
    thumbnail: '/mapstyles/pnoa.png',
    id: '/mapstyles/pnoa.json'
  }
];

export const INITIAL_MAPSTYLE = MAPSTYLES[1];

export const SUSTAINABILITYACTIONS: Array<SustainabilityActionWithIconType> = [
  {
    id: 1,
    name: 'Huella de carbono',
    description: '',
    icon: 'se-almacenamiento-carbono.png'
  },
  {
    id: 2,
    name: 'Biodiversidad',
    description: '',
    icon: 'se-conservacion-biodiversidad.png'
  },
  {
    id: 3,
    name: 'Servicios hidrográficos',
    description: '',
    icon: 'se-cuencas-hidrograficas.png'
  },
  {
    id: 4,
    name: 'Conservación del suelo',
    description: '',
    icon: 'se-conservacion-suelo.png'
  },
  {
    id: 5,
    name: 'Servicios recreativos',
    description: '',
    icon: 'se-servicios-recreativos.png'
  },
  {
    id: 6,
    name: 'Prevención de incendios',
    description: '',
    icon: 'se-prevencion-incendios.png'
  },
  {
    id: 8,
    name: 'Eliminación de invasoras',
    description: '',
    icon: 'se-eliminacion-invasoras.png'
  },
  {
    id: 9,
    name: 'Reforestación',
    description: '',
    icon: 'se-reforestar.png'
  },
  {
    id: 10,
    name: 'Infraestructuras',
    description: '',
    icon: 'se-infraestructuras.png'
  }
];

export const CERTIFICATES = [
  {
    key: CERTIFICATION_TYPES.FSC,
    label: 'FSC® (Forest Stewardship Council)',
    sublabel: 'Garantía de Gestión Sostenible',
    src: 'FSC_C118939.jpeg'
  },
  {
    key: CERTIFICATION_TYPES.FSC_SE,
    label: 'FSC® (Forest Stewardship Council)',
    sublabel: 'Servicios del ecosistema',
    src: 'FSC_C118939.jpeg'
  },
  {
    key: CERTIFICATION_TYPES.PEFC,
    label: 'PEFC® (Programme for the Endorsement of Forest Certification)',
    sublabel: 'Garantía de Gestión Sostenible',
    src: 'PEFC.png'
  },
  {
    key: CERTIFICATION_TYPES.FSC_MONTRES,
    label: 'FSC® (Forest Stewardship Council)',
    sublabel: 'Servicios del ecosistema',
    src: 'FSC-Montres.png'
  }
];

export const SPECIES = [
  {
    xorfor_id: 61,
    icon: <EucaliptoGlobulus/>
  },
  {
    xorfor_id: 61,
    icon: <EucaliptoGlobulus/>
  },
  {
    xorfor_id: 65,
    icon: <EucaliptoNitens/>
  },
  {
    xorfor_id: 91,
    icon: <Frondosas/>
  },
  {
    xorfor_id: 99,
    icon: <Frondosas/>
  },
  {
    xorfor_id: 26,
    icon: <PinusPinaster/>
  },
  {
    xorfor_id: 23,
    icon: <PinusPinea/>
  },
  {
    xorfor_id: 28,
    icon: <PinusRadiata/>
  },
  {
    xorfor_id: 21,
    icon: <PinusSylvestris/>
  },
  {
    xorfor_id: 40,
    icon: <Quercus/>
  }
];

export const WEATHER_CONDITIONS = [
  {
    id: SkyState.SUNNY,
    icon: 'sun.png'
  },
  {
    id: SkyState.HIGH_CLOUDS,
    icon: 'cloud.png'
  },
  {
    id: SkyState.PARTLY_CLOUDY,
    icon: 'cloud-sun.png'
  },
  {
    id: SkyState.OVERCAST,
    icon: 'cloud.png'
  },
  {
    id: SkyState.CLOUDY,
    icon: 'cloud.png'
  },  
  {
    id: SkyState.FOG,
    icon: 'fog.png'
  },
  {
    id: SkyState.SHOWERS,
    icon: 'rain.png'
  },    
  {
    id: SkyState.OVERCAST_AND_SHOWERS,
    icon: 'rain.png'
  },        
  {
    id: SkyState.INTERMITENT_SNOW,
    icon: 'snow.png'
  },   
  {
    id: SkyState.DRIZZLE,
    icon: 'rain.png'
  },
  {
    id: SkyState.RAIN,
    icon: 'rain.png'
  },
  {
    id: SkyState.SNOW,
    icon: 'snow.png'
  },
  {
    id: SkyState.STORMS,
    icon: 'storm.png'
  },
  {
    id: SkyState.MIST,
    icon: 'fog.png'
  },
  {
    id: SkyState.FOG_BANK,
    icon: 'fog.png'
  },
  {
    id: SkyState.MID_CLOUDS,
    icon: 'cloud.png'
  }, 
  {
    id: SkyState.WEAK_RAIN,
    icon: 'rain.png'
  },
  {
    id: SkyState.WEAK_SHOWERS,
    icon: 'rain.png'
  },
  {
    id: SkyState.STORM_THEN_CLOUDY,
    icon: 'storm.png'
  },
  {
    id: SkyState.MELTED_SNOW,
    icon: 'snow.png'
  },
  {
    id: SkyState.RAIN_HAYL,
    icon: 'rain.png'
  },
];

export const EQUIVALENCE_TONS_CARS = 10/6.5; //10 toneladas por cada 6.5 coches