import React, {FC} from 'react';

//MUI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

//MUI-ICONS
import AddIcon from '@mui/icons-material/Add';
/*import GetAppIcon from '@mui/icons-material/GetApp';*/

//GREEN BONUS
import TotalInitiatives from '@/components/admin/InitiativesSummary/TotalInitiatives';
import SustainabilityActionCard from '@/components/admin/InitiativesSummary/SustainabilityActionCard';
import CertificatesCollaboratorsCard from '@/components/admin/InitiativesSummary/CertificatesCollaboratorsCard';

//UTILS
import {useTranslation} from 'react-i18next';
import {getSummaryInitiativesData} from '@/utils/getSummaryInitiativesData';

//TYPES
import {Initiative} from '@/domain/entities/Initiative';
import {PerformerEmployee} from '@/domain/entities/PerformerEmployee';

import Paper from '@mui/material/Paper';
import CollaboratorsCarousel from '@/components/admin/InitiativesSummary/CollaboratorsCarousel';

export type InitiativesSummaryProps = {
  initiatives: Array<Initiative>;
  currentPerformer: Array<PerformerEmployee> | undefined;
};

const InitiativesSummary: FC<InitiativesSummaryProps> = ({initiatives, currentPerformer}) => {
  
  const hightlightedLogos = currentPerformer?.flatMap(p => p.performer?.secondaryLogos);
  
  const initiativesSummary = getSummaryInitiativesData(initiatives);

  const {t} = useTranslation();

  return <Box display='flex' flexDirection='column'>
    <Box display='flex' flexDirection='row' justifyContent='space-between' alignItems='flex-end'>
      <Box display='flex' flexDirection='column'>
        <Typography variant='title' color='secondary' gutterBottom>{t('initiatives.summary.title').toUpperCase()}</Typography>
        <Typography variant='paragraph' color='primary.grey'>{t('initiatives.summary.subtitle')}</Typography>
      </Box>
      {/*<Box display='flex' flexDirection='row' mr={4}>
        <GetAppIcon sx={{color:'primary.grey', mr: 1}}/>
        <Typography color='primary' >PDF | IMG</Typography>
      </Box>*/}
    </Box>
    <Box display='grid' sx={{gridTemplateColumns: 'repeat(3, 1fr)', height: '100%'}} gap={2} mt={4}>
      <TotalInitiatives initiativesSummary={initiativesSummary}/>
      <SustainabilityActionCard initiativesSummary={initiativesSummary}/>
      <CertificatesCollaboratorsCard initiativesSummary={initiativesSummary} hightlightedLogos={hightlightedLogos}/>
    </Box>
    <Box display='flex' flexDirection='row' justifyContent='flex-end' gap={1}>
      <Typography variant='details' align='right' color='primary'>{t('initiatives.summary.moreDetails')}</Typography>
      <AddIcon color='primary'/>
    </Box>
    <Paper sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', px: 3, pt: 2, pb: 30, my: 1, height: '350px'}} elevation={3}>
      <CollaboratorsCarousel collaborators={initiativesSummary.collaborators}/>
    </Paper>
  </Box>;
};
export default InitiativesSummary;



