import React, {CSSProperties, FC} from 'react';

import Box from '@mui/material/Box';

import {Collaborator} from '@/domain/entities/Collaborator';
import NewCarousel from '@/components/admin/InitiativesSummary/NewCarousel';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';


export interface CollaboratorsCarouselProps {
  collaborators: Array<Collaborator>,
}

const collaboratorImage: CSSProperties = {
  filter: 'grayscale(100%)',
  maxHeight: '100%',
  maxWidth: '100%',
  opacity: 0.5,
  objectFit: 'contain'
};

const CollaboratorsCarousel: FC<CollaboratorsCarouselProps> = ({collaborators}) => {

  const {t} = useTranslation();

  return <>
    {
      collaborators && collaborators.length !== 1
        ? <Box sx={{display: 'flex', flexDirection: 'column', gap: 3, mx: 2}}>
          <Typography variant="subtitle" align="center" sx={{mt: 3, mb: 1, fontWeight: 900, color: 'secondary.main'}}>
            {t('collaborators').toUpperCase()}
          </Typography>
          <NewCarousel data={collaborators}/>
        </Box>
        : <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '120px', gap: 3}}>
          <Typography variant="subtitle" align="center" sx={{mt: 3, mb: 1, fontWeight: 900, color: 'secondary.main'}}>
            {t('collaborators').toUpperCase()}
          </Typography>
          <img style={collaboratorImage} src={collaborators[0].image} alt={collaborators[0].title}/>
        </Box>
    }
  </>;
};

export default CollaboratorsCarousel;