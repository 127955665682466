import React, {FC} from 'react';
import Box from '@mui/material/Box';

import IsotipoBikenta from '@/components/logos/IsotipoBikenta';
import CarouselGreenBonus from '@/components/CarouselGreenBonus';

import {Performer} from '@/domain/entities/Performer';
import {SxProps} from '@mui/system/styleFunctionSx/styleFunctionSx';
import {useMediaQuery} from '@mui/material';

export interface HeaderProps {
  performers: Array<Performer>,
}

const Header: FC<HeaderProps> = ({performers}) => {
  
  const widescreen = useMediaQuery('(min-width: 900px)', {noSsr: true});
  
  const containerSx: SxProps = {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
    width: '100%',
    height: '120px',
    paddingLeft: 2
  };
  
  return <Box sx={containerSx}>
    {
      widescreen || performers.length === 1 ? <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>
        {
          performers.map(({logo, name}) => <img key={name} src={logo} alt={name}
            style={{maxHeight: '80px', maxWidth: '40vw'}}/>)
        }
      </Box>
        : <CarouselGreenBonus dots={false} autoplay={true} height='80px'>
          {
            performers.map(({logo, name}) => <img key={name} src={logo} alt={name}
              style={{maxHeight: '80px', maxWidth: '40vw'}}/>)
          }
        </CarouselGreenBonus>
    }
    <IsotipoBikenta/>
  </Box>;
};

export default Header;